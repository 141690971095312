import services1 from '../../_assets/images/services1.png'
import services2 from '../../_assets/images/services2.png'
import services3 from '../../_assets/images/services3.png'
import services4 from '../../_assets/images/services4.png'
import services5 from '../../_assets/images/services5.png'
import services6 from '../../_assets/images/services6.png'
import services7 from '../../_assets/images/services7.png'
import services8 from '../../_assets/images/services8.png'
import services9 from '../../_assets/images/services9.png'
import services10 from '../../_assets/images/services10.png'

export const home_services = {
    school_transfer_short: {
      code: 'school_transfer_short',
      ru_name: 'Прием документов для перевода детей из школы в школу',
      kk_name: 'Балаларды мектептен мектепке ауыстыру үшін құжаттарды қабылдау',
      img: services5,
      new: true,
    },
    payment_patronage_short: {
      code: 'payment_patronage_short',
      ru_name: 'Передача ребенка (детей) на патронатное воспитание и назначение выплаты денежных средств на содержание ребенка (детей), переданного патронатным воспитателям',
      kk_name: 'Баланы (балаларды) патронаттық тәрбиелеуге беру және патронат тәрбиешiлерге берiлген баланы (балаларды) асырап-бағуға ақшалай қаражат төлеуді тағайындау',
      img: services1,
    },
    payment_family_short: {
      code: 'payment_family_short',
      ru_name: 'Передача ребенка (детей) на воспитание в приемную семью и назначение выплаты денежных средств на их содержание',
      kk_name: 'Баланы (балаларды) қабылдаушы отбасына тәрбиелеуге беру және оларды асырауға ақшалай қаражат төлеуді тағайындау',
      img: services2,
    },
    children_adoption_short: {
      code: 'children_adoption_short',
      ru_name: 'Выдача справок по опеке и попечительству',
      kk_name: 'Қорғаншылық және қамқоршылық жөнінде анықтамалар беру',
      img: services3,
    },
    doc_queued_ddo_short: {
      code: 'doc_queued_ddo_short',
      ru_name: 'Постановка на очередь детей, получение направления и зачисления в детские дошкольные организации',
      kk_name: 'Мектепкі дейінгі ұйымдарға балаларды кезекке қою, жолдама алу және тіркеу',
      img: services4,
      url: '/kindergarten-services'
    },
    accept_doc_edu_short: {
      code: 'accept_doc_edu_short',
      ru_name: 'Прием документов и зачисление в организации образования (начальные, осн. ср., общ.ср, спец.)',
      kk_name: 'Білім беру ұйымдарына құжаттарды қабылдау және оқуға қабылдау (мектепке дейінгі, бастауыш, негізгі орта, жалпы орта, арнайы)',
      img: services5,
    },
    accept_school_short: {
      code: 'accept_school_short',
      ru_name: 'Прием документов для зачисления в 1 класс',
      kk_name: '1-сыныпқа қабылдау үшін құжаттарды қабылдау',
      img: services7,
    },
    academical_resignations_short: {
      code: 'academical_resignations_short',
      ru_name: 'Предоставление академических отпусков обучающимся в организациях образования',
      kk_name: 'Білім беру ұйымдарында білім алушыларға академиялық демалыстар беру',
      img: services6,
    },
    status_assignment_short: {
      forLegalPerson: true,
      code: 'status_assignment_short',
      ru_name: 'Присвоение статусов «специализированная» спортивным школам и «специализированное» отделениям спортивных школ',
      kk_name: 'Спорт мектептеріне және спорт мектептерінің бөлімшелеріне "мамандандырылған" деген мәртебе беру',
      img: services8,
    },
    government_order_short: {
      forLegalPerson: true,
      code: 'government_order_short',
      ru_name: 'Прием документов на конкурс по размещению государственного образовательного заказа на подготовку кадров с техническим, профессиональным и послесредним образованием',
      kk_name: 'Техникалық, кәсіптік және орта білімнен кейінгі білімі бар кадрларды даярлауға арналған мемлекеттік білім беру тапсырысын орналастыру бойынша конкурсқа құжаттарды қабылдау',
      img: services9,
    },
    home_for_champions_short: {
      code: 'home_for_champions_short',
      ru_name: 'Предоставление жилища чемпионам и призерам Олимпийских, Паралимпийских и Сурдлимпийских игр и пользования им',
      kk_name: 'Олимпиадалық, Паралимпиадалық және Сурдлимпиадалық ойындардың чемпиондары мен жүлдегерлеріне тұрғынжай беру және оны пайдалану',
      img: services10,
    },
  };
