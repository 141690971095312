import Button from '../../modules/Button/Button';
import { getFormValues } from '../../passport/Passport';
import { services } from '../../_helpers/Constants';
import { SaveButton } from './ServiceRequestStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { serviceRequestModule } from './ServiceRequestDucks';

export function switchSPBIN(values, katoSearch) {
  let code = 'children_region';
  const value = values.find(q => q.questionCode === code);
  const kato = value ? value.newValue[0].string : 'null';
  switch (katoSearch ? katoSearch : kato.slice(0, 4)) {
    case '1926':
      return '120540021788';
    case '1916':
      return '120640007072';
    case '1960':
      return '101240008570';
    case '1958':
      return '120640012425';
    case '1956':
      return '120640009722';
    case '1962':
      return '101240010295';
    case '1948':
      return '120640014937';
    case '1944':
      return '180540005422';
    case '1946':
      return '120640003654';
    case '1950':
      return '120640018018';
    case '1968':
      return '120640014213';
    case '1942':
      return '101240000397';
    case '1964':
      return '101140016716';
    case '1940':
      return '101240007611';
    case '1936':
      return '120640008020';
    case '1932':
      return '101240000278';
    case '1934':
      return '101240006118';
    case '1952':
      return '101140017883';
    case '1966':
      return '120640013413';
    case '1910':
      return '120640007072';
    case '3310':
      return '120640016963';
    case '3318':
      return '120540021788';
    case '3332':
      return '101240000278';
    case '3334':
      return '101240006118';
    case '3336':
      return '101140016716';
    case '3340':
      return '120640003654';
    case '3342':
      return '120640014937';
    case '3344':
      return '120640018018';
    case '3346':
      return '120640009722';
    case '3348':
      return '101240008570';

    default:
      return 'null';
  }
}

export default function ServiceRequestFormButton({
  serviceProviderBin,
  loadingSave,
  btnLoading,
  serviceProvider,
  metadataKey,
  signModal,
  user,
  data
}) {
  const { t } = useTranslation();
  const disable = useSelector(state => state[serviceRequestModule].disableFormButton);
  const ddoShortDisabled = useSelector(state => state[serviceRequestModule].ddoShortDisabled);
  return (
    <SaveButton>
      <Button
        disabled={disable || ddoShortDisabled}
        text={t('sign')}
        loading={btnLoading || loadingSave}
        onClick={() => {
          if (serviceProviderBin || serviceProvider) {
            getFormValues().then(changes => {
              if (changes && changes[data.metadataKey]) {
                if (
                  [
                    services.payment_patronage_short.code
                  ].includes(metadataKey)
                ) {
                  serviceProviderBin = switchSPBIN(changes[data.metadataKey]);
                }
                if (metadataKey === services.accept_doc_edu_short.code) {
                  serviceProviderBin = serviceProvider.bin;
                }
                if (metadataKey === services.payment_family_short.code) {
                  serviceProviderBin = serviceProvider.bin;
                }
                if (metadataKey === services.home_for_champions_short.code) {
                  serviceProviderBin = serviceProvider.bin;
                }
                if (metadataKey === services.accept_school_short.code) {
                  serviceProviderBin = serviceProvider.bin;
                }
                if (metadataKey === services.school_transfer_short.code) {
                  serviceProviderBin = serviceProvider.bin;
                }
                signModal.open({
                  serviceType: metadataKey,
                  serviceProviderIinBin: serviceProviderBin || serviceProvider.bin,
                  requesterIinBin: user.iin,
                  requestFormChanges: changes[data.metadataKey],
                  serviceProviderId: [services.accept_school_short.code, services.school_transfer_short.code].includes(metadataKey) ? serviceProvider.id : null
                });
              }
            });
          } else {
            const element = document.getElementsByClassName(`service-provider`)[0];
            if (element) {
              element.classList.add('shake');
              element.classList.add('animated');
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              });
              setTimeout(() => {
                element.classList.remove('shake');
                element.classList.remove('animated');
              }, 2000);
            }
            
          }
        }}
      />
    </SaveButton>
  );
}
